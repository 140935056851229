import { yupResolver } from "@hookform/resolvers/yup";
import { getRegisteredCountries } from "@kiss-solutions/countries";
import CheckIcon from "@mui/icons-material/Check";
import {
    Box,
    Checkbox,
    CircularProgress,
    Container,
    IconButton,
    Link,
    TextField,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, useNavigate } from "react-router-dom";
import { TERMS_OF_USE_LINK, getAllUserNames, registerUser } from "../api/usersApi";
import { i18_TRANSLATION } from "../i18nReferences";
import { getHelperText } from "../yupLocalization";
import ControlledSelection from "./ControlledSelection";
import Error from "./Error";
import { createNewUser, createUserSchema } from "./mainModel";
const DEV_SUCCESS_URL = "https://kisscal.tattoo/test-abo-erfolgreich-staging/";
const PROD_SUCCESS_URL = "https://kisscal.tattoo/abo-erfolgreich/";

const Main = () => {
    const searchParams = new URLSearchParams(document.location.search);
    const affiliate = searchParams.get("affiliate");
    const { t } = useTranslation([i18_TRANSLATION]);
    const schema = createUserSchema();
    const navigate = useNavigate();
    const countryMap = useMemo(() => getRegisteredCountries(), []);
    const { isLoading, error, data: allUsers } = useQuery({queryKey: ["allUserNames"], queryFn: getAllUserNames});

    const registerMutation = useMutation({
        mutationFn: registerUser,
        onSuccess: () => {
            window.location.href = process.env.REACT_APP_ENV === "production" ? PROD_SUCCESS_URL : DEV_SUCCESS_URL;
        },
        onError: (error) => {
            if (error?.response?.status === 429) {
                navigate("/multi_request_error");
            } else {
                navigate("/user_setup_error");
            }
        },
    });
    const allStudioNames = useMemo(() => allUsers?.map((u) => u.StudioName) || [], [allUsers]);
    const {
        control,
        formState: { errors, isValid },
        setValue,
        register,
        watch,
        handleSubmit,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
        defaultValues: createNewUser(),
    });
    const countrySelection = useMemo(
        () =>
            (countryMap &&
                Object.values(countryMap).map((c) => ({
                    id: c.country_code,
                    text: c.name,
                }))) ||
            [],
        [countryMap]
    );

    const [countryCode, studioName] = watch(["countryCode", "studioName"]);
    const studioNameInUseError = useMemo(
        () => allStudioNames.includes(studioName) && t("YUP.ERROR.STUDIO_NAME_IN_USE"),
        [allStudioNames, studioName, t]
    );

    const stateSelection = useMemo(
        () =>
            countryCode &&
            countryMap[countryCode].subregions.map(
                (r) =>
                    ({
                        id: r.code,
                        text: r.subdivision_name,
                    } || [])
            ),
        [countryCode, countryMap]
    );

    const handleCountryChanged = useCallback(() => {
        setValue("subregionId", "");
    }, [setValue]);

    const handleStartRegistration = useCallback(
        (values) => {
            const accountData = { ...values, affiliate };
            registerMutation.mutate(accountData);
        },
        [affiliate, registerMutation]
    );

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Error />;
    }
    return (
        <Form onSubmit={handleSubmit(handleStartRegistration)}>
            <Container maxWidth="md">
                <Box>
                    <Typography variant="h4" mt={1}>
                        {t("MAIN.HEADER")}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontSize: "80%" }}>
                        {t("MAIN.SUBTITLE")}
                    </Typography>
                </Box>
                <Grid container mt={1} spacing={2}>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <TextField
                            required
                            {...register("studioName")}
                            fullWidth
                            error={errors?.studioName != null || studioNameInUseError?.length > 0}
                            helperText={getHelperText(t, errors, "studioName") || studioNameInUseError}
                            variant="standard"
                            label={t("MAIN.STUDIO_NAME_LABEL")}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <TextField
                            required
                            {...register("city")}
                            fullWidth
                            error={!!errors?.city}
                            helperText={getHelperText(t, errors, "city")}
                            variant="standard"
                            label={t("MAIN.CITY_LABEL")}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <ControlledSelection
                            selections={countrySelection}
                            control={control}
                            required
                            fullWidth
                            onChange={handleCountryChanged}
                            helperText={t("MAIN.COUNTRY_SELECTION_HELPER_TEXT")}
                            labelId="country-label"
                            controllerName="countryCode"
                            label={t("MAIN.COUNTRY_LABEL")}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <ControlledSelection
                            selections={stateSelection}
                            control={control}
                            required
                            fullWidth
                            labelId="state-label"
                            error={!!errors?.subregionId}
                            helperText={getHelperText(t, errors, "subregionId")}
                            controllerName="subregionId"
                            label={t("MAIN.STATE_LABEL")}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <TextField
                            required
                            {...register("phone")}
                            fullWidth
                            error={!!errors?.phone}
                            helperText={getHelperText(t, errors, "phone")}
                            variant="standard"
                            label={t("MAIN.PHONE_LABEL")}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <TextField
                            required
                            {...register("kuerzel")}
                            fullWidth
                            error={!!errors?.kuerzel}
                            helperText={t("MAIN.ARTIST_SHORT_NAME_HELPER_TEXT")}
                            variant="standard"
                            label={t("MAIN.ARTIST_SHORT_NAME_LABEL")}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <TextField
                            required
                            {...register("email")}
                            fullWidth
                            error={!!errors?.email}
                            variant="standard"
                            label={t("MAIN.EMAIL_LABEL")}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <TextField
                            required
                            {...register("emailConfirmation")}
                            fullWidth
                            error={!!errors?.emailConfirmation}
                            variant="standard"
                            slotProps={{
                                input: {
                                    autoComplete: "new-password",
                                },
                            }}
                            label={t("MAIN.EMAIL_CONFIRMATION_LABEL")}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Controller
                            name="termsAgreed"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    sx={{
                                        color: errors?.termsAgreed ? "error.main" : "default",
                                        marginRight: 0,
                                    }}
                                />
                            )}
                        />
                        <Typography variant="p">
                            {t("I_ACCEPT")}
                            <Link href={TERMS_OF_USE_LINK} target="_blank">
                                {t("TERMS_OF_SERVICE")}
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12 }} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                            size="large"
                            color="success"
                            type="submit"
                            disabled={!isValid || studioNameInUseError?.length > 0}
                        >
                            <CheckIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
        </Form>
    );
};

export default Main;
